import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, Divider, Grid, TextField } from '@mui/material';
import { ActionButton } from "../common/components/common/inputs";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Checkbox, FormGroup, FormControlLabel, FormControl } from '@mui/material';
import useAxiosPrivate from "../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../common/hooks/useAppContext';
import { RewardSettingService } from "../services/RewardSettingService";
import { Notification } from '../common/components/common';
import notifyError from '../common/errors/notifyError';
import { disabledRoles } from '../common/data';
import { useStyles } from './styles';
import { useBaseStyles } from '../common/styles';
import { RadioGroup } from '../common/components/common/inputs';
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';



const referralItems = [
    { id: 1, value: 1000, label: '1000' },
    { id: 2, value: 2000, label: '2000' },
    { id: 3, value: 3000, label: '3000' },
    { id: 4, value: 5000, label: '5000' },
    { id: 5, value: 8000, label: '8000' },
    { id: 6, value: 10000, label: '10000' },
    { id: 7, value: 20000, label: '20000' },
]


//all 
// loyalty name
//to do : custom, set once, never change
// reminder: never change, if need to change, 
// free: delete app after install
// paid: contact to change
//merchant: store and user agreement, check out: opt in

//general:confirm


export default function Referral() {
    const classes = useStyles();
    const baseClasses = useBaseStyles();

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const [email, setEmail] = useState({ isToNotifyReferralPending: false, isToNotifyReferralPosted: false });

    const [referralBonus, setReferralBonus] = useState(0);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        RewardSettingService.getConfiguration(axiosPrivate)
            .then(response => {
                const config = response.data.configuration;
                const { isToNotifyReferralPending, isToNotifyReferralPosted } = config;
                setEmail({ isToNotifyReferralPending, isToNotifyReferralPosted })
                setReferralBonus(config.referralBonus);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);


    const handleReferralBonusChange = e => {
        e.preventDefault();
        setReferralBonus(e.target.value);
        RewardSettingService.updateReferralBonus(axiosPrivate, e.target.value)
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });
    }

    const handleNotificationChange = e => {
        e.preventDefault();
        const name = e.target.name;
        const checked = e.target.checked;
        setEmail({ ...email, [name]: checked });
        RewardSettingService.updateReferralEmailNotification(axiosPrivate, email)
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });
    }


    const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
        //the textTransform and fontSize properties only change via sx setting, instead of classes
        color: pink[800],
        '&.Mui-checked': {
            color: pink[600],
        },
    }));

    return (
        <Box sx={{ margin: 2 }}>
            <Grid container alignItems="stretch" justify='space-around' spacing={2}>

                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Referral Bonus
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Set the referral bonus points, which both the referrer and referree can receive after referree placing a first order.
                        </Typography>
                        <RadioGroup
                            name="redeem_threshold"
                            value={referralBonus}
                            onChange={handleReferralBonusChange}
                            items={referralItems}
                            disabled={disabledRoles.includes(auth.user.role)}
                            sx={{
                                color: pink[800],
                                '&.Mui-checked': {
                                    color: pink[600],
                                },
                            }}
                        />
                    </Paper>
                </Grid>


                <Grid item xs={12} md={6} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Referral Email Notification
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Typography sx={{ width: '100%', fontSize: 12, mb: 2 }} className={baseClasses.secondary}>
                            Receive email notifications when .
                        </Typography>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="Referral Points pending"
                                    control={<StyledCheckbox
                                        name="isToNotifyReferralPending"
                                        value={email.isToNotifyReferralPending}
                                        checked={email.isToNotifyReferralPending}
                                        onChange={handleNotificationChange}
                                        disabled={disabledRoles.includes(auth.user.role)}
                                    />}
                                    label="Points pending"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="Referral Points posted to account"
                                    control={<StyledCheckbox
                                        name="isToNotifyReferralPosted"
                                        value={email.isToNotifyReferralPosted}
                                        checked={email.isToNotifyReferralPosted}
                                        onChange={handleNotificationChange}
                                        disabled={disabledRoles.includes(auth.user.role)}
                                    />}
                                    label="Referral Points posted to account"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Paper>
                </Grid>

            </Grid>

            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </Box >
    )
}