import React, { useState, useEffect } from 'react';
import { Paper, TableBody, TableRow, Typography, TableCell, Box, Grid } from '@mui/material';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../common/hooks/useAppContext';
import { useTable, Notification } from '../../common/components/common';
import RewardActivityService from "../../services/RewardActivityService";
import notifyError from '../../common/errors/notifyError';
import { useBaseStyles } from '../../common/styles';
import { makeStyles } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';
import { capitalize } from '../../common/utils/string';

const headCells = [
    { id: 'date', label: 'Posted', disableSorting: true },
    { id: 'ordeNo', label: 'Order No', disableSorting: true },
    { id: 'points', label: 'Earnings', disableSorting: true },
    { id: 'description', label: 'Description', disableSorting: true }
]

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: 180
    },
    activity: {
        textAlign: 'left',
        padding: theme.spacing(2),
    }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
        // ...theme.applyStyles('dark', {
        //     backgroundColor: theme.palette.grey[800],
        // }),
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
        // ...theme.applyStyles('dark', {
        //     backgroundColor: '#308fe8',
        // }),
    },
}));


export default function RewardDetail({ customer, tiers }) {
    const classes = useStyles();
    const baseClasses = useBaseStyles();
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();
    const isHighestTier = customer.loyaltyTier == tiers.length - 1;
    const MIN = isHighestTier ? tiers[customer.loyaltyTier - 1].spendingThreshold : tiers[customer.loyaltyTier].spendingThreshold;
    const MAX = isHighestTier ? tiers[customer.loyaltyTier].spendingThreshold : tiers[customer.loyaltyTier + 1].spendingThreshold;


    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });

    const normalise = (value) => {
        if (value < MAX) {
            return ((value - MIN) * 100) / (MAX - MIN);
        } else {
            return 100;
        }
    }

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        RewardActivityService.getRewards(axiosPrivate, customer.email)
            .then(response => {
                console.log(response.data)
                isMounted && setRecords(response.data);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth, customer]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);

    return (
        <>

            <Grid container alignItems="stretch" justify='space-around' spacing={2} my={2}>
                <Grid item key={1} xs={12} md={4} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Summary
                        </Typography>
                        <br />
                        <Typography align="left" sx={{ fontSize: 16 }} >
                            Available Points: {` ${customer.available_points}`}
                        </Typography>
                        <Typography align="left" sx={{ fontSize: 16 }} >
                            Pending Points: {`  ${customer.pending_points}`}
                        </Typography>
                        <Typography align="left" sx={{ fontSize: 16 }} >
                            Tier: {`  ${capitalize(tiers[customer.loyaltyTier].name)}`}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item key={2} xs={12} md={4} className={baseClasses.secondary}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Status Progress
                        </Typography>
                        <br />
                        <BorderLinearProgress variant="determinate" sx={{ mr: 2 }} value={normalise(customer.spending, 0, 500)} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline"
                        }}>
                            <Typography sx={{ fontSize: 14, width: "50%" }} className={baseClasses.left}>
                                {customer.currency_symbol}{MIN}
                            </Typography>
                            <Typography sx={{ fontSize: 14, width: "45%" }} className={baseClasses.right}>
                                {customer.currency_symbol}{MAX}
                            </Typography>
                        </Box>
                        <Typography sx={{ width: '100%', fontSize: 14 }} className={baseClasses.middle}>
                            {isHighestTier ? capitalize(tiers[customer.loyaltyTier].name) : `${customer.currency_symbol}${MAX - customer.spending} to ${capitalize(tiers[customer.loyaltyTier + 1].name)}`}
                        </Typography>

                        <br />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <Typography sx={{ width: '20%', fontSize: 12 }}>
                            </Typography>
                            <CircleIcon fontSize="small" style={{ color: '#1a90ff' }} />
                            <Typography sx={{ width: '20%', fontSize: 12 }} className={baseClasses.left}>
                                Earned
                            </Typography>
                            <CircleIcon fontSize="small" style={{ color: grey[200] }} />
                            <Typography sx={{ width: '20%', fontSize: 12 }} className={baseClasses.left}>
                                Pending
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item key={3} xs={12} md={4} className={baseClasses.root}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Contact
                        </Typography>
                        <br />

                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline"
                        }}>
                            <Typography align="left" sx={{ fontSize: 14 }} >
                                Email:
                            </Typography>
                            <Typography align="left" sx={{ fontSize: 12 }} >
                                {`  ${customer.email}`}
                            </Typography>
                        </Box>

                        <Typography align="left" sx={{ fontSize: 14 }} >
                            Address:
                        </Typography>

                        <Typography align="left" sx={{ fontSize: 12 }} >
                            {`             ${customer.billing_address.street_1}`} {'\n'}
                            {`             ${customer.billing_address.city}`} {customer.billing_address.state} {'\n'}
                            {`             ${customer.billing_address.zip}`} {'\n'}
                            {`             ${customer.billing_address.country}`}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} className={baseClasses.root}>
                    <br />
                    <Paper className={classes.activity}>
                        <Typography variant="h5" sx={{ width: '100%' }}>
                            Activity
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>

                            <TblContainer>
                                <TblHead />
                                <TableBody>
                                    {
                                        recordsAfterPagingAndSorting().map((item, index) =>
                                        (<TableRow key={index}>
                                            <TableCell>{item.posted_date}</TableCell>
                                            <TableCell>{item.orderNo}</TableCell>
                                            <TableCell>{item.points}</TableCell>
                                            <TableCell>{item.description}</TableCell>
                                        </TableRow>)
                                        )
                                    }
                                </TableBody>
                            </TblContainer>
                            <TblPagination />
                        </Box>
                    </Paper>
                </Grid>
            </Grid >
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
        </>
    )
}