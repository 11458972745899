//sorted by startDate
function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = ascendingComparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function ascendingComparator(a, b) {
    if (a.startDate < b.startDate) {
        return -1;
    } else if (a.startDate > b.startDate) {
        return 1;
    }

    return 0;
}

//check endDate
function findFirstV1(list, item) {
    const endDate = item.endDate;
    let left = 0, right = list.length - 1;
    while (left <= right) {
        const mid = left + (right - left) / 2;
        if (list[mid].startDate >= endDate) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }

    return left;
}

export function checkUniqueName(records, campaign) {
    //exclude the campaign itself
    if (records.find((item) => item._id != campaign._id && item.name == campaign.name)) {
        return false;
    } else {
        return true;
    }
}

// return true if campaign is overlapped with existing campaigns
export default function checkOverlapped(records, campaign) {
    //filter out the campaign and disabled campaigns
    // !== does not convert data types
    let campaigns = records.filter((item) => item._id !== campaign._id && item.isEnabled);
    campaigns = stableSort(campaigns);

    let idx = campaigns.length;
    //check endDate
    if (campaign.endDate) {
        idx = findFirstV1(campaigns, campaign);
    }

    //point: campaigns[idx - 1].endDate is Date in mongoDB on server side, but 
    //typeof campaigns[idx - 1].endDate is string after network transfer
    //campaign.startDate can also be a string, first adding, saving and editing

    //check startDate with previous zone
    if (idx >= 1 && (!campaigns[idx - 1].endDate || new Date(campaigns[idx - 1].endDate) > new Date(campaign.startDate))) {
        return { overlapped: true, name: campaigns[idx - 1].name };
    } else {
        return { overlapped: false };
    }
}


