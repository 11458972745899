import React, { useState, useReducer } from 'react';
import { useForm, Form } from '../../common/components/common/useForm';
import { Button } from '../../common/components/common/inputs';
import { TextField, Box, MenuItem, Switch, Typography, Stack } from '@mui/material';

import { reducer } from '../../common/components/common/reducer';
import { defaultState, closeModal } from '../RuleUtil';
import Modal from '../../common/components/common/Modal';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { printDateAndTime } from '../../common/utils/date/print';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const multiplierItems = [
    { id: 2, value: 2, label: '2X' },
    { id: 3, value: 3, label: '3X' },
    { id: 4, value: 4, label: '4X' },
    { id: 5, value: 5, label: '5X' },
    { id: 6, value: 6, label: '6X' },
    { id: 7, value: 7, label: '7X' },
    { id: 8, value: 8, label: '8X' },
    { id: 9, value: 9, label: '9X' },
    { id: 10, value: 10, label: '10X' },
    { id: 11, value: 11, label: '11X' },
    { id: 12, value: 12, label: '12X' },
    { id: 13, value: 13, label: '13X' },
    { id: 14, value: 14, label: '14X' },
    { id: 15, value: 15, label: '15X' },
    { id: 16, value: 16, label: '16X' },
    { id: 17, value: 17, label: '17X' },
    { id: 18, value: 18, label: '18X' },
    { id: 19, value: 19, label: '19X' },
    { id: 20, value: 20, label: '20X' },
]

export default function CampignForm(props) {
    const { addOrEdit, recordForEdit, timeZone } = props;
    dayjs.tz.setDefault(timeZone);
    const localDate = new Date(); // include the locale time zone
    const storeDatejs = dayjs.tz(localDate); // convert to the store time zone


    const [startDate, setStartDate] = useState(recordForEdit ? dayjs.tz(new Date(recordForEdit.startDate)) : storeDatejs);
    const [endDate, setEndDate] = useState(recordForEdit?.endDate ? dayjs.tz(new Date(recordForEdit.endDate)) : null);
    const [checked, setChecked] = useState(recordForEdit ? recordForEdit.isEnabled : true);

    const initCampaign = {
        _id: 0,
        name: '',
        multiplier: 2,
        startDate: localDate,
        startFormat: printDateAndTime(storeDatejs),
        isEnabled: true
    }

    const resetCampaign = { ...initCampaign };
    if (recordForEdit) {
        resetCampaign._id = recordForEdit._id;
    }
    const [state, dispatch] = useReducer(reducer, defaultState);

    const {
        values,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(recordForEdit || initCampaign, resetCampaign, false);


    //*****NOTE*****
    //To respond to reset button,  useEffect with values dependency is slow,
    // Moreover, it works only when adding following code to the handleCountryChange function
    // setValues({ ...values, country });
    // (change the country part of the values to trigger values to be stored in a different address,
    //so when clicking on reset button to setValues, the above useEffect condition can be fired surely)
    //The reason is it will be called whenever the storage address of values changes, due to monitored in the event loop


    const handleReset = () => {
        resetForm();
    }

    //point: dayjs format function cannot be called in Campaigns (maybe because dayjs is not imported)
    const handleStartChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndChange = (newValue) => {
        setEndDate(newValue);
    };

    const handleSwitchChange = (e) => {
        e.preventDefault();
        setChecked(e.target.checked);
        setValues({ ...values, isEnabled: e.target.checked });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (values.name && values.startDate) {
            // point: $d is still the locale time zone, should convert it by calling utc()
            // however, format it is in the correct timeZone. They do not agree
            if (endDate) {
                addOrEdit({
                    ...values, startDate: startDate.utc().$d, startFormat: printDateAndTime(startDate),
                    endDate: endDate.utc().$d, endFormat: printDateAndTime(endDate)
                }, resetForm);
            } else {
                addOrEdit({
                    ...values, startDate: startDate.utc().$d, startFormat: printDateAndTime(startDate)
                }, resetForm);
            }
        } else {
            dispatch({ type: 'NO_VALUE' });
        }
    };

    return (
        <>
            {state.isModalOpen && <Modal closeModal={() => closeModal(dispatch)} modalContent={state.modalContent} />}

            <Form onSubmit={handleSubmit}>
                <Box sx={{
                    mx: 'auto',
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 400,
                    width: '100%',
                    overflow: "hidden"
                    // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}>
                    <TextField
                        type='text'
                        label="Name"
                        variant="outlined"
                        size="small"
                        name="name"
                        value={values.name || ''}
                        onChange={handleInputChange}
                        sx={{
                            width: '100%'
                        }}
                        required
                    />


                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            required
                            label="Start Date&Time"
                            name="startDate"
                            value={startDate}
                            onChange={handleStartChange}
                            renderInput={(params) => <TextField {...params} />}
                        />

                        <DateTimePicker
                            label="End Date&Time"
                            name="endDate"
                            value={endDate}
                            onChange={handleEndChange}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                    <TextField
                        required
                        label="Multiplier Points"
                        variant="outlined"
                        size="small"
                        name="multiplier"
                        value={values.multiplier || ''}
                        onChange={handleInputChange}
                        select
                    >
                        {
                            multiplierItems.map((item, index) => {
                                return (<MenuItem key={index} value={item.value}>{item.label}</MenuItem>)
                            })
                        }
                    </TextField >

                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography>Paused</Typography>
                        <Switch
                            checked={checked}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography>Active</Typography>
                    </Stack>

                </Box>

                <Button
                    type="submit"
                    text="Submit" />
                <Button
                    text="Reset"
                    color="grey"
                    onClick={handleReset} />
            </Form>
        </>
    )
}


