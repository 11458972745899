import React, { useState, useEffect } from 'react';
import CampaignForm from "../../components/campaigns/CampaignForm";
import { Paper, Box, TableBody, TableRow, TableCell, InputAdornment, Grid, Switch, Typography } from '@mui/material';
import { Button, ActionButton, TextInput } from "../../common/components/common/inputs";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAppContext from '../../common/hooks/useAppContext';
import { CampaignService } from "../../services/CampaignService";
import { useTable, Popup, PageHeader, Notification, ConfirmDialog } from '../../common/components/common';
import notifyError from '../../common/errors/notifyError';
import { useStyles } from '../styles';
import { disabledRoles } from '../../common/data';
import checkOverlapped, { checkUniqueName } from './campaignUtil';

const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'multiplier', label: 'Multiplier of Points' },
    { id: 'starDate', label: 'Start' },
    { id: 'endDate', label: 'End' },
    { id: 'enabled', label: 'Enabled' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function Campaigns() {
    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [openPopup, setOpenPopup] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, store } = useAppContext();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    const [timeZone, setTimeZone] = useState();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        CampaignService.getCampaigns(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data.campaigns);
                isMounted && setTimeZone(response.data.timeZone);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);


    const addOrEdit = async (campaign, resetForm) => {
        try {
            if (!checkUniqueName(records, campaign)) {
                setNotify({
                    isOpen: true,
                    message: `The campaign name ${campaign.name} already exists`,
                    type: 'error'
                })
                return;
            }

            const { overlapped, name } = checkOverlapped(records, campaign);
            if (overlapped) {
                setNotify({
                    isOpen: true,
                    message: `The interval of the new campaign is overlapped with ${name}`,
                    type: 'error'
                })
            }
            else {
                if (campaign._id === 0) {
                    const { data } = await CampaignService.addCampaign(axiosPrivate, campaign);
                    const newCampaign = { ...campaign, _id: data._id, storeId: store._id };
                    setRecords([...records, newCampaign]);
                }
                else {
                    //_id may have changed into a new one
                    const { data: { _id } } = await CampaignService.updateCampaign(axiosPrivate, campaign);
                    const newRecords = [...records];
                    let recordIndex = newRecords.findIndex(x => x._id === campaign._id);
                    newRecords[recordIndex] = { ...campaign, _id }; // update _id, in case it is changed
                    setRecords([...newRecords]);
                }

                resetForm()
                setRecordForEdit(null)
                setOpenPopup(false)

                setNotify({
                    isOpen: true,
                    message: 'Submitted Successfully',
                    type: 'success'
                })
            }
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }

    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.name ? x.name.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }

    const handleDelete = async id => {
        try {
            await CampaignService.removeCampaign(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== id);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    const handleSwitchChange = async (item, e) => {
        if (e.target.checked) {
            const { overlapped, name } = checkOverlapped(records, item);
            if (overlapped) {
                setNotify({
                    isOpen: true,
                    message: `The interval of the new campaign is overlapped with ${name}`,
                    type: 'error'
                })
                return;
            }
        }

        item.isEnabled = e.target.checked;
        try {
            await CampaignService.switchCampaignStatus(axiosPrivate, item);
            const newRecords = [...records];
            let recordIndex = newRecords.findIndex(x => x._id === item._id);
            newRecords[recordIndex] = { ...item }
            setRecords([...newRecords]);
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }

    };


    return (
        <>
            <PageHeader
                title="Reward Campaigns"
            />

            <Paper className={classes.pageContent}>
                <Box sx={{
                    minWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Grid container alignItems="center">
                        <Grid item xs={4} lg={3}>
                            <Typography sx={{ display: 'inline-block' }}>
                                Time Zone:{' '}
                            </Typography>
                            <Typography sx={{ display: 'inline-block' }}>
                                {timeZone}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} lg={6}>
                            <TextInput
                                label="Search Campaigns"
                                size="small"
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <Button
                                sx={{ whiteSpace: "normal" }}
                                text="Add Campaign"
                                variant="outlined"
                                size="medium"
                                disabled={disabledRoles.includes(auth.user.role)}
                                startIcon={<AddIcon />}
                                onClick={() => { setRecordForEdit(null); setOpenPopup(true); }}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (
                                    <TableRow TableRow key={item._id} >
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.multiplier}X</TableCell>
                                        <TableCell>{item.startFormat}</TableCell>
                                        <TableCell>{item.endFormat ? item.endFormat : 'Not Set'}</TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={item.isEnabled}
                                                onChange={(e) => handleSwitchChange(item, e)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <ActionButton
                                                color="primary"
                                                disabled={disabledRoles.includes(auth.user.role)}
                                                onClick={() => { openInPopup(item) }}>
                                                <EditOutlinedIcon fontSize="small" />
                                            </ActionButton>
                                            <ActionButton
                                                color="secondary"
                                                disabled={disabledRoles.includes(auth.user.role)}
                                                onClick={() => {
                                                    setConfirmDialog({
                                                        isOpen: true,
                                                        title: 'Are you sure to delete this record?',
                                                        subTitle: "You can't undo this operation",
                                                        onConfirm: () => handleDelete(item._id)
                                                    })
                                                }}
                                            >
                                                <CloseIcon fontSize="small" />
                                            </ActionButton>

                                        </TableCell>
                                    </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper >
            <Popup
                title="Campaign Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <CampaignForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit}
                    timeZone={timeZone}
                />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}