export const CampaignService = {
    getCampaigns(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/campaigns`);
    },

    //point: set email in the request data (body)
    addCampaign(axiosPrivate, campaign) {
        return axiosPrivate.post(`/api/v1/campaigns`, { campaign });
    },

    //point: delete request does not have body entity because it is ignored by express
    removeCampaign(axiosPrivate, campaignId) {
        return axiosPrivate.delete(`/api/v1/campaigns/${campaignId}`);
    },

    updateCampaign(axiosPrivate, newCampaign) {
        return axiosPrivate.put(`/api/v1/campaigns`, { newCampaign });
    },

    switchCampaignStatus(axiosPrivate, campaign) {
        return axiosPrivate.put(`/api/v1/campaigns/campaign_status`, { campaign });
    },

}

//java static class