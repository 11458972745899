export const RewardSettingService = {
    getConfiguration(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/reward_settings`);
    },

    updateProgramName(axiosPrivate, programName) {
        return axiosPrivate.patch(`/api/v1/reward_settings/program_name`, { programName });
    },

    updateDelay(axiosPrivate, postPointsDelay_days) {
        return axiosPrivate.patch(`/api/v1/reward_settings/point_pending`, { postPointsDelay_days });
    },

    updateExpiring(axiosPrivate, pointExpiring_years) {
        return axiosPrivate.patch(`/api/v1/reward_settings/point_expiring`, { pointExpiring_years });
    },

    updateEmailNotification(axiosPrivate, email) {
        return axiosPrivate.patch(`/api/v1/reward_settings/email_notification`, email);
    },

    updateGuestEarningEnabled(axiosPrivate, isToEnableGuestEarning) {
        return axiosPrivate.patch(`/api/v1/reward_settings/guest_earning_enabled`, { isToEnableGuestEarning });
    },

    updateRedeemThreshold(axiosPrivate, redeemThreshold) {
        return axiosPrivate.patch(`/api/v1/reward_settings/redeem_threshold`, { redeemThreshold });
    },

    updateDiscountPercentageLimit(axiosPrivate, discountPercentageLimit) {
        return axiosPrivate.patch('/api/v1/reward_settings/discount_percentage_limit', { discountPercentageLimit })
    },


    updateReferralBonus(axiosPrivate, referralBonus) {
        return axiosPrivate.patch(`/api/v1/reward_settings/referral_bonus`, { referralBonus });
    },

    updateReferralEmailNotification(axiosPrivate, email) {
        return axiosPrivate.patch(`/api/v1/reward_settings/referral_email_notification`, email);
    }
}

//java static class