import React, { useState, useEffect } from 'react';
import TierForm from "../components/tiers/TierForm";
import { Paper, Box, TableBody, TableRow, TableCell, InputAdornment, Grid } from '@mui/material';
import { Button, ActionButton, TextInput } from "../common/components/common/inputs";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import useAxiosPrivate from "../common/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../common/hooks/useAppContext';
import { LoyaltyTierService } from "../services/LoyaltyTierService";
import { useTable, Popup, PageHeader, Notification, ConfirmDialog } from '../common/components/common';
import notifyError from '../common/errors/notifyError';
import { useStyles } from './styles';
import { disabledRoles } from '../common/data';

const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'spendingThreshold', label: 'Spending Threshold' },
    { id: 'pointsPerSpending', label: 'Points Earned per $1 Spending' },
    { id: 'milestoneBonus', label: 'Milestone Bonus' },
    { id: 'actions', label: 'Actions', disableSorting: true }
]

export default function MembershipTiers() {
    const classes = useStyles();
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [records, setRecords] = useState([]);
    const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
    const [openPopup, setOpenPopup] = useState(false);

    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    const { auth } = useAuth();

    const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' });
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        LoyaltyTierService.getTiers(axiosPrivate)
            .then(response => {
                isMounted && setRecords(response.data);
            })
            .catch(err => {
                notifyError(err, setNotify, navigate, location);
            });

        return () => {
            isMounted = false;
            controller.abort();
        }
    }, [axiosPrivate, location, navigate, auth]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting
    } = useTable(records, headCells, filterFn);


    const addOrEdit = async (tier, resetForm) => {
        try {
            if (tier._id === 0) {
                const { data } = await LoyaltyTierService.addTier(axiosPrivate, tier)
                const newTier = { ...tier, _id: data._id };
                console.log(newTier);
                setRecords([...records, newTier]);
            }
            else {
                await LoyaltyTierService.updateTier(axiosPrivate, tier)
                const newRecords = [...records];
                let recordIndex = newRecords.findIndex(x => x._id === tier._id);
                newRecords[recordIndex] = { ...tier }
                setRecords([...newRecords]);
            }

            resetForm()
            setRecordForEdit(null)
            setOpenPopup(false)

            setNotify({
                isOpen: true,
                message: 'Submitted Successfully',
                type: 'success'
            })
        } catch (err) {
            notifyError(err, setNotify, navigate, location);
        }
    }

    const handleSearch = e => {
        let target = e.target;
        setFilterFn({
            fn: items => {
                if (target.value === "")
                    return items;
                else
                    return items.filter(x => x.name ? x.name.toLowerCase().includes(target.value.toLowerCase()) : false)
            }
        })
    }

    const handleDelete = async id => {
        try {
            await LoyaltyTierService.removeTier(axiosPrivate, id);

            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            const newRecords = records.filter((item) => item._id !== id);
            setRecords(newRecords);
            setNotify({
                isOpen: true,
                message: 'Deleted Successfully',
                type: 'error'
            })
        } catch (err) {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            })
            notifyError(err, setNotify, navigate, location);
        }
    }

    const openInPopup = item => {
        setRecordForEdit(item)
        setOpenPopup(true)
    }

    return (
        <>
            <PageHeader
                title="Membership Tiers"
            />

            <Paper className={classes.pageContent}>
                <Box sx={{
                    minWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Grid container >
                        <Grid item xs={8} lg={9}>
                            <TextInput
                                label="Search Tiers"
                                size="small"
                                className={classes.searchInput}
                                InputProps={{
                                    startAdornment: (<InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>)
                                }}
                                onChange={handleSearch}
                            />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                            <Button
                                sx={{ whiteSpace: "normal" }}
                                text="Add Tier"
                                variant="outlined"
                                size="medium"
                                disabled={disabledRoles.includes(auth.user.role)}
                                startIcon={<AddIcon />}
                                onClick={() => { setRecordForEdit(null); setOpenPopup(true); }}
                            />
                        </Grid>
                    </Grid>
                    <TblContainer>
                        <TblHead />
                        <TableBody>
                            {
                                recordsAfterPagingAndSorting().map(item =>
                                (<TableRow key={item._id}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.spendingThreshold}</TableCell>
                                    <TableCell>{item.pointsPerSpending}</TableCell>
                                    <TableCell>{item.milestoneBonus}</TableCell>
                                    <TableCell>
                                        <ActionButton
                                            color="primary"
                                            disabled={disabledRoles.includes(auth.user.role)}
                                            onClick={() => { openInPopup(item) }}>
                                            <EditOutlinedIcon fontSize="small" />
                                        </ActionButton>
                                        <ActionButton
                                            color="secondary"
                                            disabled={disabledRoles.includes(auth.user.role)}
                                            onClick={() => {
                                                setConfirmDialog({
                                                    isOpen: true,
                                                    title: 'Are you sure to delete this record?',
                                                    subTitle: "You can't undo this operation",
                                                    onConfirm: () => handleDelete(item._id)
                                                })
                                            }}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </ActionButton>

                                    </TableCell>
                                </TableRow>)
                                )
                            }
                        </TableBody>
                    </TblContainer>
                    <TblPagination />
                </Box>
            </Paper>
            <Popup
                title="Address Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <TierForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit} />
            </Popup>
            <Notification
                notify={notify}
                setNotify={setNotify}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}