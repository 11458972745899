const { months, irregDates } = require('./data');

function printDate(datejs) {
    const year = datejs.$y;
    const month = months[datejs.$M];
    const date = datejs.$D; //1 - 31

    return formatDateToStandard({ month, date, year });
}

function formatDateToStandard({ month, date, year }) {
    if (date < 4) {
        return `${month} ${irregDates[date - 1]}, ${year}`;
    } else if (date < 31) {
        return `${month} ${date}, ${year}`;
    } else {
        return `${month} 31st, ${year}`;
    }
}

function printTime(datejs) {
    let hours = datejs.$H;
    let minutes = datejs.$m;

    let tag = 'AM';

    if (hours >= 12) {
        tag = 'PM';
        if (hours >= 13) {
            hours -= 12;
        }
    }

    if (minutes < 10) {
        return `${hours}:0${minutes} ${tag}`;
    } else {
        return `${hours}:${minutes} ${tag}`
    }
}


function printDateAndTime(datejs) {
    const date = printDate(datejs);
    const time = printTime(datejs);

    return `${date} ${time}`;
}


module.exports = {
    printDate,
    printTime,
    printDateAndTime
}