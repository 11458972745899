export const LoyaltyTierService = {
    getTiers(axiosPrivate) {
        return axiosPrivate.get(`/api/v1/loyalty_tiers`);
    },

    //point: set email in the request data (body)
    addTier(axiosPrivate, tier) {
        return axiosPrivate.post(`/api/v1/loyalty_tiers`, { tier });
    },

    //point: delete request does not have body entity because it is ignored by express
    removeTier(axiosPrivate, tierId) {
        return axiosPrivate.delete(`/api/v1/loyalty_tiers/${tierId}`);
    },

    updateTier(axiosPrivate, newTier) {
        return axiosPrivate.put(`/api/v1/loyalty_tiers`, { newTier });
    },

}

//java static class