const months = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
const irregDates = ["1st", "2nd", '3rd'];

const numOfDays = [
    31, //"Jan",
    28, //"Feb",
    31, // "March",
    30, // "Apr",
    31, // "May",
    30, // "June",
    31, // "July",
    31, // "Aug",
    30, // "Sep",
    31, // "Oct",
    30, // "Nov",
    31, // "Dec"
];


module.exports = {
    months,
    irregDates,
    numOfDays
}