import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import "./App.css";

import AdminMiniDrawer from "./common/components/drawer/AdminMiniDrawer";
import AdminProfile from "./common/views/AdminProfile";
import Billing from "./common/views/Settings/Billing";
import Cards from "./common/views/Settings/Cards";
import CheckoutForm from './common/views/Settings/CheckoutForm';
import { Cancel } from './common/components/stripe';
import Users from "./common/views/Settings/Users";

import Orders from "./views/Orders";
import Customers from "./views/Customers";

import Campaigns from './views/Campaigns/Campaigns';
import MembershipTiers from "./views/MembershipTiers";
import Referral from "./views/Referral";
import General from "./views/General";

import ManuBar from "./common/components/ManuBar";
import AdminLogin from './common/views/Public/AdminLogin';
import Logout from './common/views/Logout';
import AdminRegister from './common/views/Public/AdminRegister';
import AdminForgotPasswordAllInOne from "./common/views/Public/AdminForgotPasswordAllInOne";
import ResetPassword from "./common/views/Public/OldResetPassword";
import AdminVerifyEmail from "./common/views/Public/AdminVerifyEmail";

import Unauthorized from "./common/views/Unauthorized";
import Error from './common/views/Error';
import RequireAuth from "./common/views/RequireAuth";
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import AdminPersistLogin from "./common/views/AdminPersistLogin";
import { useLogoutAllTabs } from './common/hooks/useBroadcast';
import Stores from './common/views/Admin/Stores';

function App() {
  const theme = createTheme();
  const logoutAllTabs = useLogoutAllTabs();

  useEffect(() => {
    logoutAllTabs();
  }, [logoutAllTabs])

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<ManuBar />} >
            <Route index element={<AdminLogin />} />
            <Route path="index.html" element={<AdminLogin />} />
            <Route path="login" element={<AdminLogin />} />
            <Route path='register' element={<AdminRegister />} />

            <Route path='user/verify_email' exact element={<AdminVerifyEmail />} />
            <Route path='user/forgot_password' exact element={<AdminForgotPasswordAllInOne />} />

            {/* Following is used by forgot password + reset password ( version 1 ) */}
            <Route path='user/reset_password' exact element={<ResetPassword />} />
          </Route>

          {/* we want to protect these routes*/}
          <Route element={<AdminPersistLogin />}>
            <Route element={<RequireAuth allowedRoles={['admin_user', 'owner', 'admin', 'user']} />} >
              <Route path="/orders" element={<AdminMiniDrawer />} >
                <Route index element={<Orders />} />
              </Route>

              <Route path="/customers" element={<AdminMiniDrawer />} >
                <Route index element={<Customers />} />
              </Route>

              <Route path="/loyalty_tiers" element={<AdminMiniDrawer />} >
                <Route index element={< MembershipTiers />} />
              </Route>

              <Route path="/referral" element={<AdminMiniDrawer />} >
                <Route index element={< Referral />} />
              </Route>

              <Route path="/campaigns" element={<AdminMiniDrawer />} >
                <Route index element={<Campaigns />} />
              </Route>

              <Route path="/manage" element={<AdminMiniDrawer />} >
                <Route path="unauthorized" element={<Unauthorized />} />
              </Route>

              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route index element={<General />} />
                <Route path="general" element={<General />} />
                <Route path='users' element={<Users />} />
              </Route>

              <Route path="/stores" element={<AdminMiniDrawer />} >
                <Route index element={<Stores />} />
              </Route>

              {/* <Route path="/tickets" element={<AdminMiniDrawer />} >
                <Route index element={<Tickets />} />
              </Route> */}

              <Route path="/profile" element={<AdminMiniDrawer />} >
                <Route index element={<AdminProfile />} />
              </Route>

              <Route path="/logout" element={<Logout />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={['admin_user', 'owner', 'admin']} />} >
              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route path='billing' element={<Billing />} />
                <Route path='subscribe' element={<CheckoutForm />} />
                <Route path='payment_methods' element={<Cards />} />
              </Route>
            </Route>

            <Route element={<RequireAuth allowedRoles={['admin_user']} />} >
              <Route path="/settings" element={<AdminMiniDrawer />} >
                <Route path='cancel' element={<Cancel />} />
              </Route>
            </Route>

          </Route>

          {/* Catch all */}
          <Route path='*' element={<Error />} />

        </Routes>
      </div>
    </ThemeProvider >

  );
}


export default App;
